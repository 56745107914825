import React, { useState, useEffect } from "react"
import NewsPage from "../components/news-page"
import * as haikus from "../model/haikus"

const IndexPage = (foo) => {
  const [haikuData, setHaikuData] = useState([]);
  const [pageDates, setPageDates] = useState([]);

  const pageDate = pageDates[pageDates.length -1]

  useEffect(()=>{
    haikus.getHaikusForDate(pageDate).then((haikuData)=>{
      setHaikuData(haikuData)
    })
  }, [pageDate])

  useEffect(()=>{
    haikus.getPageDates().then((pageDates)=>{
      setPageDates(pageDates)
    })
  }, [])

  return (
    <NewsPage pageContext={{
      pageDate: pageDate,
      pageDates: pageDates,
      haikuData: haikuData
    }}/>
  )}

export default IndexPage
